<template>
  <div id="account-dashboard">
    <v-row>
      <v-col cols="12" md="3">
        <v-card :loading="submitImage" class="mx-auto">
          <v-img
            v-if="!account.avatar"
            height="250"
            src="https://cdn.vuetifyjs.com/images/cards/cooking.png"
          ></v-img>

          <v-img v-else height="250" :src="account.avatar"></v-img>

          <div class="change-picture px-3 mt-3">
            <v-btn
              block
              outlined
              color="primary"
              :disabled="submitImage"
              @click="onButtonClick"
            >
              Change Your Avatar
            </v-btn>
            <input
              ref="uploader"
              class="d-none"
              type="file"
              accept="image/*"
              @change="onFileChanged"
            />
          </div>

          <v-card-text>
            <div class="text-caption">
              Besar file: maksimum 10.000.000 bytes (10 Megabytes). Ekstensi
              file yang diperbolehkan: .JPG .JPEG .PNG.
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="9">
        <div class="personal-info mb-3">
          <div class="info-title text-overline font-weight-black mb-5">
            Personal Information
          </div>
          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Full Name
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              <span v-if="account.name">{{ account.name }}</span>
              <span v-else>Your Full Name</span>
            </div>
            <div class="info-item-action">
              <v-btn x-small color="primary" text @click="changeName = true"
                >Change</v-btn
              >
            </div>
          </div>

          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Birth Date
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              <span v-if="account.birth_date">{{ account.birth_date }}</span>
              <span v-else>Your Birth Date</span>
            </div>
            <div class="info-item-action">
              <v-btn x-small color="primary" text @click="changeBirth = true"
                >Change</v-btn
              >
            </div>
          </div>

          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Gender
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              <span v-if="account.gender == 0"> Your Gender </span>
              <span v-if="account.gender == 1"> Male </span>
              <span v-if="account.gender == 2"> Female </span>
            </div>
            <div class="info-item-action">
              <v-btn x-small color="primary" text @click="changeGender = true"
                >Change</v-btn
              >
            </div>
          </div>
        </div>

        <div class="contact-info mb-3">
          <div class="info-title text-overline font-weight-black mb-5">
            Contact Information
          </div>
          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Email
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              {{ account.email }}
            </div>
          </div>

          <div class="info-item d-flex my-3">
            <div class="info-item-title text-subtitle-2 font-weight-light">
              Phone Number
            </div>
            <div class="info-item-value mr-3 font-weight-light">
              <span v-if="account.phone">{{ account.phone }}</span>
              <span v-else>Your Phone Number</span>
            </div>
            <div class="info-item-action">
              <v-btn x-small color="primary" text @click="changePhone = true"
                >Change</v-btn
              >
            </div>
          </div>
        </div>

        <!-- <div class="mt-10">
          <v-btn outlined color="primary">Change Password</v-btn>
        </div> -->
      </v-col>
    </v-row>

    <v-dialog v-model="changeName" max-width="400">
      <form @submit.prevent="updateName">
        <v-card>
          <v-card-title class="text-h5 mb-5"> Change Full Name </v-card-title>

          <div class="dialog-form px-5 mb-10">
            <span class="mb-3">Your Full Name</span>
            <v-text-field
              solo
              hide-details="auto"
              :value="account.name"
              v-model="form.name"
            ></v-text-field>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="changeName = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary"
              type="submit"
              @click="dialog = false"
              :disabled="submitForm"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <v-dialog v-model="changeBirth" max-width="400" scrollable>
      <form @submit.prevent="updateBirth">
        <v-card>
          <v-card-title class="text-h5 mb-5"> Change Bith Date </v-card-title>

          <v-card-text>
            <div class="dialog-form px-5 mb-10">
              <span class="mb-3">Your Birth Date</span>
              <v-date-picker v-model="form.date" :max="today"></v-date-picker>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="changeBirth = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary"
              type="submit"
              @click="dialog = false"
              :disabled="submitForm"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <v-dialog v-model="changeGender" max-width="400" scrollable>
      <form @submit.prevent="updateGender">
        <v-card>
          <v-card-title class="text-h5 mb-5"> Change Your Gender </v-card-title>

          <v-card-text>
            <div class="dialog-form px-5 mb-10">
              <v-radio-group v-model="form.gender" row>
                <v-radio value="1">
                  <template v-slot:label>
                    <div class="text-center">
                      <v-icon size="100">mdi-face-man-outline</v-icon>
                      <div class="text-overline">Male</div>
                    </div>
                  </template>
                </v-radio>
                <v-spacer></v-spacer>
                <v-radio value="2">
                  <template v-slot:label>
                    <div class="text-center">
                      <v-icon size="100">mdi-face-woman-outline</v-icon>
                      <div class="text-overline">Female</div>
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="changeGender = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary"
              type="submit"
              @click="dialog = false"
              :disabled="submitForm"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>

    <v-dialog v-model="changePhone" max-width="400">
      <form @submit.prevent="updatePhone">
        <v-card>
          <v-card-title class="text-h5 mb-5">
            Change Phone Number
          </v-card-title>

          <div class="dialog-form px-5 mb-10">
            <span class="mb-3">Your Phone Number</span>
            <v-text-field
              solo
              hide-details="auto"
              :value="account.phone"
              v-model="form.phone"
            ></v-text-field>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="primary" text @click="changePhone = false">
              Cancel
            </v-btn>

            <v-btn
              color="primary"
              type="submit"
              @click="dialog = false"
              :disabled="submitForm"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </form>
    </v-dialog>
  </div>
</template>
<style>
.info-item-title {
  width: 200px;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      changeName: false,
      changeBirth: false,
      changeGender: false,
      changePhone: false,
      account: [],
      loading: false,

      form: {
        name: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        gender: "",
        phone: "",
      },

      today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),

      loadingData: false,
      submitForm: false,
      submitImage: false,

      isSelecting: false,
      selectedFile: null,
    };
  },
  methods: {
    onButtonClick() {
      this.isSelecting = true;
      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
      this.submitImage = true;

      var fm = new FormData();
      fm.append("image", this.selectedFile);

      axios
        .post("customer/updateAvatar", fm, {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then((response) => {
          this.getData();
          this.changeAvatar = false;
          this.submitImage = false;
          console.log(response);
        })
        .catch((error) => console.log(error))
        .finally(() => (this.submitImage = false));
    },

    updatePhone() {
      this.submitForm = true;
      axios
        .post(
          "customer/updatePhone",
          {
            phone: this.form.phone,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.getData();
          this.changePhone = false;
          console.log(response);
          this.submitForm = false;
        })
        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },

    updateGender() {
      this.submitForm = true;
      axios
        .post(
          "customer/updateGender",
          {
            gender: this.form.gender,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.getData();
          this.changeGender = false;
          console.log(response);
          this.submitForm = false;
        })
        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },

    updateBirth() {
      this.submitForm = true;
      axios
        .post(
          "customer/updateBirth",
          {
            birth: this.form.date,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.getData();
          this.changeBirth = false;
          console.log(response);
          this.submitForm = false;
        })
        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },
    updateName() {
      this.submitForm = true;
      axios
        .post(
          "customer/updateName",
          {
            name: this.form.name,
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "*",
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          this.getData();
          this.changeName = false;
          console.log(response);
          this.submitForm = false;
        })
        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },
    getData() {
      this.loadingData = true;

      axios
        .get("customer/account", {
          headers: {
            "Access-Control-Allow-Headers": "*",
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((response) => {
          console.log(response);
          this.account = response.data.data.user;
          this.loadingData = true;
        })

        .catch((error) => {
          console.log(error);
          this.submitForm = false;
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
